export default class TreeNodeCollection {
  set internalArray(value = []) {
    this._internalArray = value;
  }
  set length(value = 0) {
    this._length = value;
  }
  get internalArray() {
    return this._internalArray || [];
  }
  get length() {
    return this._length || 0;
  }

  constructor() {
    this.internalArray = [];
    this.nodeToAdd = {};
    this.nodeToRemove = {};
  }

  firstNode() {
    var nodeCount = this.internalArray.length;

    if (nodeCount > 0) {
      return this.internalArray[0];
    } else {
      return undefined;
    }
  }

  lastNode() {
    var nodeCount = this.internalArray.length;

    if (nodeCount > 0) {
      return this.internalArray[nodeCount - 1];
    } else {
      return undefined;
    }
  }

  addNode(nodeToAdd) {
    nodeToAdd.siblings = this;
    nodeToAdd.previousSibling = this.lastNode();
    if (nodeToAdd.previousSibling) {
      nodeToAdd.previousSibling.nextSibling = nodeToAdd;
    }
    nodeToAdd.nextSibling = undefined;
    this.internalArray.push(nodeToAdd);
    this.length++;
  }

  removeNode(nodeToRemove) {
    nodeToRemove.siblings = this;
    nodeToRemove.previousSibling = this.lastNode();
    if (nodeToRemove.previousSibling) {
      nodeToRemove.previousSibling.nextSibling = nodeToRemove;
    }
    nodeToRemove.nextSibling = undefined;
    this.internalArray.pop(nodeToRemove);
    this.length++;
  }

  find(value, matchFunction_node_value) {
    var matchFunction = matchFunction_node_value,
      internalArray = this.internalArray;

    if (!matchFunction) {
      if (typeof value !== "string") {
        // match objects JSON values
        matchFunction = function (nodeToMatch, valueToMatchOn) {
          return nodeToMatch.tagName === value.tagName;
        };
      } else {
        // default string match is a function that matches by name;
        matchFunction = function (nodeToMatch, valueToMatchOn) {
          return nodeToMatch.tagName === valueToMatchOn;
        };
      }
    }

    for (var i = 0; i < internalArray.length; i++) {
      if (matchFunction(internalArray[i], value)) {
        return internalArray[i];
      }
    }

    return null;
  }

  nodeAt(index) {
    return this.internalArray[index];
  }

  each(callback) {
    for (var i = 0; i < this.internalArray.length; i++) {
      callback(this.internalArray[i]);
    }
  }
}
