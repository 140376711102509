import $ from "jquery";
import Tree from "./Tree.js";

//inside
const addPageNameAttribute = (element, pageName) => {
  if (element) {
    if (pageName.trim()) {
      $(element).attr("pg-page-name", pageName);
      // console.log("ele::", element);
    }
    return element;
  }
};


//export
const convertHTMLToObject = (htmlText, pageName) => {
  // const findedPageNodes = fetchOnePage(pageId).done((response) => {
  //   return response;
  // });

  // nextPageNodes.forEach((item) => {
  //   if (prevPage.id) {
  //     item.pageId = pageId.id;
  //     let pervJsonConfig;
  //     const foundItem = findedPageNodes.pageNodes.find(
  //       (node) => node.id === item.id
  //     );
  //     if (foundItem?.jsonConfig) {
  //       item.jsonConfig = foundItem.jsonConfig;
  //     }
  //   } else {
  //     item.pageId = undefined;
  //   }
  //   return item;
  // });

  // const appspace = {
  //   applicationId: match.params.applicationId,
  //   componentId: match.params.componentId,
  // };

  const parser = new DOMParser();
  const workspace = parser.parseFromString(htmlText, "text/html");
  // const pageName = "HomePage";
  // console.log("workspace::", workspace);


  /* root node need 
    <div id="xxx" class="page__content" style="min-height: 680px">
     <div id="xxx" class="container">
        <div name="workspace">
                        // Your html for page
                </div>
        </div>
    </div>
  */
  const findPageRootNode = (pageName) => {
    // const frame = workspace.querySelector('div[name="workspace"]');
    const frame = workspace.querySelector('body');
    // console.log("frame::", frame);
    if (frame) {
      return addPageNameAttribute(frame, pageName);
    }
  };

  const pageRootNode = findPageRootNode(pageName);
  // console.log("pageRootNode::", pageRootNode);
  // const defaultPageType = findDefaultPageType(pageRootNode);
  const copyPageRootNode = pageRootNode?.cloneNode(true);
  // console.log("copyPageRootNode::+", copyPageRootNode);

  const pageTreeInstance = Tree.createFromDOM(copyPageRootNode);
  // console.log("pageTreeInstance::+", pageTreeInstance);

  let nodeTransform = pageTreeInstance.simpleDecorateNodeFunction;
  // console.log("nodeTransform::+", nodeTransform);

  const nextPageNodes = pageTreeInstance.toFlatJSONObject(
    false,
    false,
    nodeTransform
  );

  const nextPage = {
    page: Object.assign(
      {},
      {
        pageNodes: nextPageNodes,
        pageType: "page",
      }
    ),
    appspace: "appspace",
  };

  // console.log("nextPageNodes::", nextPageNodes);
  // console.log("nextPage::", nextPage);
  // savePage(nextPage);
  return nextPage;
};

// convertHTMLToObject(htmlText, "HomePage");

//export  // req : html , (footer , header)
const convertHTMLToObjectForHeaderFooter = (htmlText, pageName) => {

  const parser = new DOMParser();
  const workspace = parser.parseFromString(htmlText, "text/html");


  /* 
    root node need <footer></footer>
    or root node need <header></header>
  */
  const findPageRootNodeForHeaderFooter = (pageName) => {
    //footer , header
    const frame = workspace.querySelector(pageName);
    if (frame) {
      return addPageNameAttribute(frame, pageName);
    }
  };

  // const pageRootNode = findPageRootNodeForHeaderFooter("footer");
  const pageRootNode = findPageRootNodeForHeaderFooter(pageName);
  const pageTreeInstance = Tree.createFromDOM(pageRootNode);
  let nodeTransform = pageTreeInstance.simpleDecorateNodeFunction;
  const nextPageNodes = pageTreeInstance.toFlatJSONObject(
    false,
    false,
    nodeTransform
  );
  return nextPageNodes;
};


/* 
    CONVERT_HTML_TO_OBJECT(htmlText,pageName)
    pageName => defind name ex : "HomePage"
    htmlText => root node need : 
      <div id="xxx" class="page__content" style="min-height: 680px">
        <div id="xxx" class="container">
            <div name="workspace">
                          // Your html for page
            </div>
        </div>
      </div>
*/
console.log("register CONVERT_HTML_TO_OBJECT");
Object.defineProperty(window, "CONVERT_HTML_TO_OBJECT", {
  get: function () {
    return convertHTMLToObject;
  },
  configurable: true,
});

/* 
    CONVERT_HTML_TO_OBJECT_HEADER_FOOTER(htmlText,pageName)
    pageName => fixed name : footer, header
    htmlText => root node need : 
              <footer>
                // Your html for page
              </footer>
    or 
              <header>
                // Your html for page
              </header>
*/
console.log("register CONVERT_HTML_TO_OBJECT_HEADER_FOOTER");
Object.defineProperty(window, "CONVERT_HTML_TO_OBJECT_HEADER_FOOTER", {
  get: function () {
    return convertHTMLToObjectForHeaderFooter;
  },
  configurable: true,
});
