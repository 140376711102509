import TreeNodeCollection from "./TreeNodeCollection";

export default class TreeNode {
  constructor(tagName) {
    if (tagName) {
      this._tagName = tagName;
    }
    this._children = new TreeNodeCollection();
    this._siblings = new TreeNodeCollection();
  }

  addChild(childNode) {
    childNode.parent = this;
    this.children.addNode(childNode);
    return childNode;
  }

  set nodeType(nodeType = { id: 1 }) {
    this._nodeType = nodeType;
  }
  get nodeType() {
    return this._nodeType || { id: 1 };
  }
  set tagName(value) {
    this._tagName = value;
  }
  set parent(value) {
    this._parent = value;
  }
  set siblings(value) {
    this._siblings = value;
  }
  set children(value) {
    this._children = value;
  }
  set previousSibling(value) {
    this._previousSibling = value;
  }
  set nextSibling(value) {
    this._nextSibling = value;
  }
  get tagName() {
    return this._tagName;
  }
  get parent() {
    return this._parent;
  }
  get siblings() {
    return this._siblings;
  }
  get children() {
    return this._children;
  }
  get previousSibling() {
    return this._previousSibling;
  }
  get nextSibling() {
    return this._nextSibling;
  }
}
