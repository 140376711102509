import $ from "jquery";
import each from "lodash/each";
import TreeNode from "./TreeNode";

export const OnsElementRules = {
  HTML: "html",
  Body: "body",
  Page: "ons-page",
  EmptyPage: "ons-page#empty_page",
  PageContent: "div.page__content",
  PageBg: "div.page__background",
  Footer: "ons-bottom-toolbar",
  Toolbar: "ons-toolbar",
  ToolbarCenter: "ons-toolbar div.center",
  ToolbarLeft: "ons-toolbar div.left",
  ToolbarRight: "ons-toolbar div.right",
  ToolbarBackButton: "ons-back-button",
  ToolbarButton: "ons-toolbar-button",
  TabBar: "ons-tabbar",
  TabGroup: "div.tabbar",
  TabBarContent: "div.tabbar__content.ons-tabbar__content",
  TabItem: "ons-tab",
  TabLabel: "div.tabbar__label",
  Splitter: "ons-splitter",
  SplitterSide: "ons-splitter-side",
  SplitterContent: "ons-splitter-content",
  SplitterMask: "ons-splitter-mask",
  SearchInput: "ons-search-input",
  Input: "ons-input",
  Button: "ons-button",
  List: "ons-list",
  ListHeader: "ons-list-header",
  ListItem: "ons-list-item",
  ListItemCenter: "div.center.list-item__center",
  ListItemLeft: "div.left.list-item__left",
  ListItemRight: "div.right.list-item__right",
  TextArea: "textarea",
  Center: "div.center",
  Left: "div.left",
  Right: "div.right",
  Image: "img",
  Icon: "ons-icon",
  Card: "ons-card",
  CardContent: "div.content.card__content",
  Label: "label",
  Link: "a",
  SelectInput: "ons-select",
  HTMLSelect: "select",
  Row: "ons-row",
  Column: "ons-col",
  Switch: "ons-switch",
  Radio: "ons-radio",
  CheckBox: "ons-checkbox",
  Range: "ons-range",
  Carousel: "ons-carousel",
  Modal: "ons-dialog ",
  ModalDialog: "div.dialog",
  ModalContent: "div.dialog-container",
  DialogMask: "div.dialog-mask",
  Form: "form",
  SignaturePad: "pgd-digital-signature",
  SignatureDrawer: "div.signature-drawer",
  Canvas: "canvas",
  Notification: "span.notification",
  QRCode: "pg-qrcode",
  Chart: "pg-chart",
  WebComponent: "webComponent",
  TemplateComponent: "templateComponent",
  Container: "div.container",
  /**  Please always set [Div] to last index  */
  Div: "div",
};

export const ElementGroups = {
  body: ["html", "body", "head"],
  voidelements: [
    "I",
    "AREA",
    "BASE",
    "BR",
    "COL",
    "COMMAND",
    "EMBED",
    "HR",
    "IMG",
    "INPUT",
    "KEYGEN",
    "LINK",
    "META",
    "PARAM",
    "VIDEO",
    "IFRAME",
    "SOURCE",
    "TRACK",
    "WBR",
    "SELECT",
  ],
  formElement: ["INPUT", "TEXTAREA", "SELECT"],
  stateLessEl: ["BUTTON", "INPUT", "TEXTAREA", "IMG", "LABEL", "SPAN"],
  heading: ["H1", "H2", "H3", "H4", "H5", "H6", "H7"],
  smallText: ["STRONG", "EM", "I", "U", "B", "SUB", "SUP", "VAR"],
  paragraph: [
    "P",
    "SPAN",
    "BLOCKQUOTE",
    "CITE",
    "SMALL",
    "CODE",
    "CAPTION",
    "LABEL",
  ],
  tableCell: ["TD", "TH"],
  tableRow: ["tr", "thead", "tbody", "tfooter"],
  content: ["DIV", "TD", "TH"],
};

export function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export function uuid() {
  let uid = `${s4()}${s4()}${s4()}-${s4()}${s4()}-${s4()}${s4()}`;
  return uid;
}

export const PgCore = {
  name: /^(page-designer)$/gim,
  nameValue: "page-designer",
  prefix: /^(pg_{2})([\d\w]+)$/gim,
  prefixValue: "pg__",
  classNamePrefix: /^(pg|pgcss|pgcore)_{2}([\d\w]+)$/gim,
  classNamePrefixValue: ["pg__", "pgcss__", "pgcore__"],
  dataAttrPrefix: /^(data)-(pgcore|pgdnd)?-([\d\w]+)/gim,
  psoudoAttribute: /^(_{2})([\w|\-|_]+)(_{2})$/gm,
  dataAttrPrefixValue: ["data-pgcore-", "data-pgdnd-"],
  customeElementPrefix: /^(pgi)([_|-]{0,1})([\d\w]+)$/gim,
  customeElementPrefixValue: ["pg", "pg_", "pg-"],
  elementIDPrefix: /^(pg)([_|-]{1})([\d\w]+)$/gim,
  elementIDPrefixValue: ["pg_", "pg-"],
  psoudoElementPrefix: /^(pg)([_|-]{1})([\d\w]+)$/gim,
  psoudoElementPrefixValue: ["pg_", "pg-"],
};

export const Attributes = {
  PAGE_SECTION: "data-pgroot",
  PAGE_ID: "data-pg-pageid",
  NODE_ID: "data-pg-id",
  NODE_KEY: "data-pg-key",
  NODE_STATE: "pg-state",
  NODE_DATA: "pg-store",
  NODE_DATA_EXT: "pg-data",
  NODE_STATE_DATA: "pg-state-store",
  NODE_IMAGE: "pg-image",
  PAGE_NAME: "pg-page-name",
  IS_LIST: "pg-is-list",
  IS_INFINITESCROLL: "infinite-scroll",
  IS_TABS: "pg-is-tabs",
  BUFFERSIZE: "buffer-size",
  TAB_KEY: "key",
  TAB_ACTIVE: "active-key",
  LIMITED: "pg-limited",
  ID: "id",
  INPUT_ID: "input-id",
  DESIGN_ONLY: "pg-ignore",
  PID: "__page_id__",
  NID: "__node_id__",
  NKEY: "__node_key__",
  NPKEY: "__node_parent_key__",
  IFRAME: "__iframe_wrapper__",
  RICHTEXT: "__richtext_wrapper__",
  CONTROL: "__control_wrapper__",
  NORMAL: "__normal_wrapper__",
  OUTLINE: "__outline_on__",
  CLONE: "__clone__",
  SIMULATED: "__simulated__",
  TYPE: "__type__",
  LOCK: "__lock__",
  DF_PROJECT: "pg-dataflow-appid",
  DF_PROCESS_ID: "pg-dataflow-pid",
  DF_PROCESS_NAME: "pg-dataflow-name",
  DF_TYPE: "pg-dataflow-type",
  DF_EVENT: "pg-dataflow-event",
  DF_CONF: "pg-dataflow-mapped",
  OPTIONS: "pg-options",
  NG_MODEL: "ng-model",
  SELECT_MODEL: "pg-select-model",
  SELECT_MODEL_VALUE_FIELD: "pg-select-model-value-field",
  SELECT_MAPPING_TO: "pg-select-mapping-to",
  SELECT_LIST: "pg-select-list",
  REF_ID_IAM2: "pg-access-control",
  OBJ_ID_IAM2: "pg-access-obj-id",
  INPUT_FILTER: "pg-input-filter",
  INPUT_FILTER_LIST: "pg-filter-list",
  INPUT_FILTER_VALUE_FIELD: "pg-select-model-value-field",
  dataInherit: "data-inherit",
  SIGNATURE_CLEAR: "pg-signature-clear",
  SIGNATURE_SAVE: "pg-signature-save",
  MIN_WIDTH_OF_LINE: "pg-min-width",
  MAX_WIDTH_OF_LINE: "pg-max-width",
  PEN_COLOR: "pg-pen-color",
  SIGNATURE_PAD: "pg-signature-pad",
  LINK_METHOD: "pg-ref-option",
  IS_POP: "pg-is-pop",
  ANIMATION: "pg-animation",
  LINK_ROUTE: "pg-link-route",
  CLASS: "class",
  LINK: "pg-ref",
  LINK_MODAL: "pg-mdref",
  STYLE: "style",
  LINK_DATA: "pg-link-data",
  NODE_FLOW: "pg-flow",
  TRACK_BY: "pg-trackby",
  OPTION_VALUE: "pg-option-value",
  OPTION_LABEL: "pg-option-label",
  DEFAULT_VALUE: "pg-default",
  MENU_NAME: "pg-menu-name",
  IMAGE_TYPE: "pg-image-type",
  LINK_SPLITTER_ROUTE: "pg-sref",
  IMAGE_ID: "pg-image-id",
  NODE_EMBED: "pg-embed",
};

function checkObject(el, nodeName, current) {
  current += 1;
  let id = "#" + nodeName + current;
  let found = $(el.ownerDocument).find(id);
  if (found.length > 0) {
    return checkObject(el, nodeName, current);
  }
  return current;
}

export function generateDOMID(el, map = counterMapping) {
  if (el) {
    let nodeName = el.nodeName;
    let isText =
      [...ElementGroups.smallText, ...ElementGroups.paragraph].indexOf(
        nodeName
      ) > -1;
    let isHeader = ElementGroups.heading.indexOf(nodeName) > -1;
    if (isText) {
      nodeName = "text";
    } else if (isHeader) {
      nodeName = "title";
    }
    nodeName = nodeName.toLowerCase();
    if (typeof map === "object") {
      let current = map[nodeName] || 1;
      map[nodeName] = checkObject(el, nodeName, current);
      return `${nodeName}${map[nodeName]}`;
    } else {
      let b = el.getBoundingClientRect();
      let unique =
        parseInt(b.right + b.top, 10) + parseInt(b.left + b.bottom, 10);
      let nodeID = `${nodeName}${unique}`;
      if (nodeID.length > 25) {
        nodeID = nodeID.substring(0, 25);
      }
      return nodeID;
    }
  }
}

function doubleQuoteToSingle(str) {
  return str;
}
export default class Tree {
  static isNull(value) {
    // a forgiving null check to cater for null values that are string representations of null
    return value == null || value === "null" || value === "NULL";
  }

  constructor(root) {
    if (!root) {
      this.root = new TreeNode("body");
    } else {
      this.root = root;
    }
    // assign the tree as the parent of the root node
    this.root.parent = this;
  }

  findNode(value, prop) {
    var matchFunction = prop,
      match = null;

    if (Tree.isNull(matchFunction)) {
      if (typeof value !== "string") {
        // match objects JSON values
        matchFunction = function (nodeToMatch, valueToMatchOn) {
          return nodeToMatch.tagName === value.tagName;
        };
      } else {
        // default string match is a function that matches by tagName;
        matchFunction = function (nodeToMatch, valueToMatchOn) {
          return nodeToMatch.tagName === valueToMatchOn;
        };
      }
    } else if (typeof matchFunction === "string") {
      matchFunction = function (nodeToMatch, valueToMatchOn) {
        return nodeToMatch[prop] === value[prop];
      };
    }

    this.nodes().each(function (node, cancel) {
      if (matchFunction(node, value)) {
        match = node;
        cancel = true;
      }
    });

    return match;
  }

  nodes() {
    // nodes function provides a full suite of methods for traversing the nodes of a tree
    var current = null, // keeps a reference to the current node
      parentTree = this, // maintains a reference to the tree
      traversing = false, // a flag to indicate whether we have started traversing or not
      traversalQueue = [];

    return {
      reset() {
        current = null;
        traversalQueue = [];
        traversing = false;
      },
      initTraversal() {
        // begin traversing :
        // - set the current node
        current = parentTree.root;
        // - start off the traversal Queue
        traversalQueue.push(current);
        // - set the traversal flag to true
        traversing = true;
      },
      next() {
        if (!traversing) {
          this.initTraversal();
        }

        current = traversalQueue.shift();
        if (current) {
          // enqueue the children (bread-first-traversal)
          current.children.each(function (childToEnqueue) {
            traversalQueue.push(childToEnqueue);
          });
        }

        return current;
      },
      each(callback) {
        var cancel = false,
          node = this.next();

        while (node && !cancel) {
          callback(node, cancel);
          node = this.next();
        }
      },
    };
  }

  /**
   * default decorator function.
   * simple data will include tagName:string and children:[] by default.
   * simple decorator include aother attribute following regex
   * accept:
   * ```
   * /^_?(payload|props)|^([a-zA-Z])+(.*)$/.test(node)
   * ```
   * exclude:
   * ```
   * !/^_?(size|children|parentKey|key)$/.test(node)
   * ```
   *
   *
   */
  simpleDecorateNodeFunction(objectToDecorate, originalNode) {
    for (let prop in originalNode) {
      if (
        !/^_?(size|children|parentKey|key)$/.test(prop) &&
        /^_?(payload|nodeType)|^([a-zA-Z])+(.*)$/.test(prop) &&
        {}.hasOwnProperty.call(originalNode, prop)
      ) {
        var newKey = prop.replace(/^_?(payload|nodeType)$/, "$1");
        let parsedVal = {};
        try {
          parsedVal = JSON.parse(originalNode[prop]);
        } catch (ex) {
          parsedVal = originalNode[prop];
        }
        objectToDecorate[newKey] = parsedVal;
      }
    }
    return objectToDecorate;
  }

  /**
   * Object to hierarchy JSON
   */
  toSimpleObject(decorateNodeFunction = this.simpleDecorateNodeFunction) {
    var writeChildNodes = function (nodeCollection) {
      var simpleChildRepresentation = [];
      if (nodeCollection) {
        nodeCollection.each(function (node) {
          var children = writeChildNodes(node.children);
          simpleChildRepresentation.push(
            decorateNodeFunction(
              {
                key: node.key,
                tagName: node.tagName,
                children: children,
              },
              node
            )
          );
        });
      }
      return simpleChildRepresentation;
    };

    return decorateNodeFunction(
      {
        key: this.root.key,
        tagName: this.root.tagName,
        children: writeChildNodes(this.root.children),
      },
      this.root
    );
  }

  toFlatJSONObject(
    sorted = false,
    isCreateNew = false,
    decorateNodeFunction = this.simpleDecorateNodeFunction
  ) {
    var flatTable = [];
    var writeChildNodes = function (nodeCollection, flatTable) {
      if (nodeCollection) {
        nodeCollection.each(function (node) {
          if (isCreateNew && node.key) {
            node.key = null;
          }
          if (node.tagName === OnsElementRules.TabItem) {
            node.props.forEach((item, key) => {
              if (item.name === "className") {
                item.value = item.value.replace("active", "");
              }
              if (item.name === "active") {
                node.props.splice(key, 1);
              }
            });
          }
          let nextNode = decorateNodeFunction(
            {
              key: node.key,
              parentKey: node.parentKey,
              tagName: node.tagName,
            },
            node
          );
          flatTable.push(nextNode);
          writeChildNodes(node.children, flatTable);
        });
      }
      return flatTable;
    };
    var rootNode = decorateNodeFunction(
      {
        key: this.root.key,
        parentKey: this.root.parentKey,
        tagName: this.root.tagName,
      },
      this.root
    );
    flatTable.push(rootNode);
    writeChildNodes(this.root.children, flatTable);
    // return sorted ? flatTable.sort(sorted) : flatTable;
    return flatTable;
  }

  domDecorateNodeFunction(
    objectToDecorate,
    originalNode,
    options = {
      ignoreRenderNodeKey: false,
      iframeDoc: document,
    }
  ) {
    var domRender = new PageRender();
    originalNode.key = objectToDecorate.key;
    var element = domRender.createNode(originalNode, options);
    var classList = element.classList;
    function dontRenderProps(prop) {
      let regex = new RegExp(
        /^_?(node[a-zA-Z0-9]+|treeLevel|leftOrder|parent?[K|k]ey)$/
      );
      let dateTimeRegex = new RegExp(
        /^(timestamp|update|create|version)[a-zA-Z]*$/
      );
      let ignoreProp = regex.test(prop);
      let ignoreDateProp = dateTimeRegex.test(prop);
      let ignorePageId = prop.indexOf("pageId") > -1;
      return ignoreProp || ignoreDateProp || ignorePageId;
    }
    for (let prop in originalNode) {
      if (!{}.hasOwnProperty.call(originalNode, prop)) {
        continue;
      } else if (dontRenderProps(prop)) {
        continue;
      } else if ("state" === prop) {
        var nodeState = originalNode[prop] ? originalNode[prop] : [];
        nodeState.forEach((attr) => {
          element.setAttribute(attr.name, attr.value);
        });
      } else if (/^(props)$/.test(prop) && originalNode[prop]) {
        //all property object from prop of node to element prop;
        var nodeProps = originalNode[prop] || [];
        //TODO fix data-pg/data-/attr
        //TreeNode.id => data-pg-id
        //TreeNode.key => data-pg-key
        //TreeNode.props[i].key =>  <el key="">
        nodeProps.forEach((attr) => {
          if (["ng-view", "ngView"].indexOf(attr.name) > -1) {
            return;
          }
          if (/^(class|class?[nN]{1}ame)$/gi.test(attr.name)) {
            try {
              let classNames = (attr.value || "").split(/\s+/g);
              if (classNames.length) {
                classNames.forEach((classCx) => {
                  if (classCx && !/\s+$/g.test(classCx)) {
                    classList.add(classCx.replace(/\s+/g, ""));
                  }
                });
              }
            } catch (errAdd) {
              console.error("[errAdd]:", attr, errAdd);
            }
          } else {
            element.setAttribute(attr.name, attr.value || "");
          }
        });
      } else if (/^(dataList)$/.test(prop)) {
        var nodeData = originalNode[prop] || [];
        //TODO fix data-pg/data-/attr
        //TreeNode.key => data-pg-key
        //TreeNode.key => data-pg-key
        //TreeNode.props[i].key =>  <el key="">
        nodeData.forEach((attr) => {
          element.setAttribute("data-" + attr.name, attr.value);
        });
      } else if (
        /^_?(payload|type)|^([a-zA-Z])+(.*)$/.test(prop) &&
        originalNode[prop]
      ) {
        //TreeNode prop lv0. to data-pg-[prop_name] attr of element.
        // nodeData.forEach((attr) => {
        if (typeof element.setAttribute === "function") {
          let valueOfProp = originalNode[prop];
          if (typeof valueOfProp === "object") {
            valueOfProp = JSON.stringify(valueOfProp);
          }
          if (prop !== "jsonConfig") {
            element.setAttribute(`data-pg-${prop}`, valueOfProp);
          }
        }
      }
    }
    return element;
  }

  toDOMTreeObject(
    options = {
      ignoreRenderNodeKey: false,
      iframeDoc: document,
    },
    decorateNode = this.domDecorateNodeFunction
  ) {
    let ignoreRenderNodeKey = options && options.ignoreRenderNodeKey;
    let iframeDoc = options && options.iframeDoc;
    //build children of root.
    var writeChildNodes = function (nodeCollection, root) {
      if (nodeCollection) {
        try {
          nodeCollection.each(function (node) {
            var nextNode = decorateNode(
              {
                key: ignoreRenderNodeKey ? null : node.key,
                tagName: node.tagName,
                nodeType: node.nodeType,
              },
              node,
              { ignoreRenderNodeKey, iframeDoc }
            );
            writeChildNodes(node.children, nextNode);
            root.appendChild(nextNode);
          });
        } catch (e) {
          console.error("ERROR", e);
        }
      }
      return root;
    };
    //return root and recursive childNode.
    var rootNode = decorateNode(
      {
        key: ignoreRenderNodeKey ? null : this.root.key,
        _isRoot: true,
        tagName: this.root.tagName,
        nodeType: this.root.nodeType,
      },
      this.root,
      { ignoreRenderNodeKey }
    );
    writeChildNodes(this.root.children, rootNode);
    return rootNode;
  }

  /**
   * not support un ordering parentNode and childNode
   * childNode came after parentNode only.
   * @returns Tree;
   */
  static createFromFlatTable(flatTable, error) {
    function sortFlatTable(table) {
      return table.sort(
        DOMUtils.sortBy({ name: "treeLevel" }, { name: "leftOrder" })
      );
    }
    // factory method to create a tree given a flat table
    var tree;
    if (flatTable && flatTable instanceof Array) {
      tree = new Tree();
      //do sorting
      let sortedFlatTable = sortFlatTable(flatTable);
      for (var i = 0; i < sortedFlatTable.length; i++) {
        var rowToConvertToNode = sortedFlatTable[i];

        if (!rowToConvertToNode.parentKey) {
          // this is the root node
          var root = tree.root;
          for (let keyToCopy in rowToConvertToNode) {
            if ({}.hasOwnProperty.call(rowToConvertToNode, keyToCopy)) {
              root[keyToCopy] = rowToConvertToNode[keyToCopy];
            }
          }
        } else {
          // this is a child node, find the parent...
          var matchByKey = function (nodeToMatch, valueToMatchOn) {
            return nodeToMatch.key === valueToMatchOn;
          };
          var parentNode = tree.findNode(
            rowToConvertToNode.parentKey,
            matchByKey
          );

          // if we managed to find the parentNode for this rowToConvert, add it as a child
          if (parentNode) {
            var convertedNode = parentNode.addChild(new TreeNode());
            for (let keyToCopy in rowToConvertToNode) {
              if ({}.hasOwnProperty.call(rowToConvertToNode, keyToCopy)) {
                convertedNode[keyToCopy] = rowToConvertToNode[keyToCopy];
              }
            }
          } else {
            console.warn(
              ":Unable to find a parent node for NODE:",
              rowToConvertToNode
            );
            if (typeof error === "function") {
              error(
                new NodeNotFoundException(
                  `unable to find a parent node for row ${rowToConvertToNode.tagName}`
                )
              );
            }
          }
        }
      }
    }
    return tree;
  }

  static createFromDOM(node, isCreateNew = false, ignore = true) {
    let counterMapping = {};
    // var tree;
    // let treeLevel = 0;
    if (!node) {
      return undefined;
    }
    if (node.nodeType === 3 || node.nodeType === 8) {
      throw new NodeNotFoundException(
        `Can not build tree from ${node.tagName || node.nodeName} node`
      );
    }
    const ignoreNode = (node) => {
      if (!node) {
        return true;
      }
      // Ignore ons-back-button.
      /**
       * TODO move to ignoreChild in file ElementRules.js.
       */
      if (
        (!$(node).is(OnsElementRules.ToolbarBackButton) &&
          $(node).closest(OnsElementRules.ToolbarBackButton).length > 0) ||
        (!$(node).is(OnsElementRules.TabItem) &&
          $(node).closest(OnsElementRules.TabItem).length > 0) ||
        $(node).is(OnsElementRules.TabBarContent) ||
        $(node).is(OnsElementRules.SplitterMask)
      ) {
        return true;
      }
      let tagName = node.nodeName;
      let nodeType = node.nodeType;
      let attributes = node.attributes;

      if (nodeType !== 1) {
        return false;
      }

      let testedElement = PgCore.customeElementPrefix.test(tagName);
      if (testedElement) {
        return true;
      }

      let hasIgnored = false;
      each(attributes, (item) => {
        if (PgCore.dataAttrPrefix.test(item.name)) {
          hasIgnored = true;
        } else if (item.name === "__clone__") {
          hasIgnored = true;
        }
      });
      return hasIgnored;
    };

    const buildDOMTree = (
      originalNode,
      parentTreeNode,
      treeLevel = 0,
      leftOrder = 0
    ) => {
      let tree = new Tree();
      var currentNode = tree.root;
      currentNode.parentKey = parentTreeNode.key || null;
      currentNode.treeLevel = treeLevel;
      currentNode.leftOrder = leftOrder;
      currentNode.nodeType = { id: originalNode.nodeType };
      if (originalNode.tagName) {
        currentNode.tagName = originalNode.tagName.toLowerCase();
      }
      if (originalNode.nodeName && !originalNode.tagName) {
        currentNode.nodeName = originalNode.nodeName.toLowerCase();
        currentNode.tagName = originalNode.nodeName.toLowerCase();
      }
      if (originalNode.nodeValue) {
        currentNode.innerText = JSON.stringify(originalNode.nodeValue);
      }
      let attributes = originalNode.attributes;
      if (attributes) {
        var length = attributes.length;
        var connectDataAttr = [
          Attributes.NODE_STATE,
          Attributes.NODE_DATA,
          Attributes.NODE_IMAGE,
          Attributes.OPTIONS,
          Attributes.NODE_FLOW,
          Attributes.TRACK_BY,
          Attributes.OPTION_LABEL,
          Attributes.OPTION_VALUE,
          Attributes.DEFAULT_VALUE,
          Attributes.IS_LIST,
          Attributes.INPUT_FILTER,
          Attributes.INPUT_FILTER_LIST,
          Attributes.INPUT_FILTER_VALUE_FIELD,
          Attributes.NODE_IMAGE,
          Attributes.NODE_STATE_DATA,
          Attributes.NG_MODEL,
          Attributes.SELECT_MODEL,
          Attributes.SELECT_MODEL_VALUE_FIELD,
          Attributes.SELECT_LIST,
          Attributes.SELECT_MAPPING_TO,
          Attributes.NODE_DATA_EXT,
          Attributes.NODE_EMBED,
        ];
        /**
         *  Arr reference to root.props momory address.
         *  every arr changed affect on obj.props too.
         * */
        var arrAttr = (currentNode.props = []);
        var arrState = (currentNode.state = []);
        for (var i = 0; i < length; i++) {
          let attr = attributes[i];
          if (
            /^pg-empty/.test(attr.nodeName) ||
            PgCore.psoudoAttribute.test(attr.nodeName)
          ) {
            // ignore custom attribute from serializer.
            continue;
          } else if (Attributes.NODE_KEY === attr.nodeName) {
            currentNode["key"] = attr.nodeValue;
          } else if (connectDataAttr.includes(attr.nodeName)) {
            let objectKey = attr.name;
            arrState.push({
              name: objectKey,
              value: doubleQuoteToSingle(attr.nodeValue),
            });
          } else if (/^data-pg-?([a-zA-Z0-9\-_]+)/.test(attr.nodeName)) {
            let objectKey = attr.name.replace(
              /^data-pg-?([a-zA-Z0-9\-_]+)$/,
              "$1"
            );
            currentNode[objectKey] = doubleQuoteToSingle(attr.nodeValue);
            //store application data TreeNode.(a-zA-Z0-9)
          } else if (
            !/^(class|draggable|contenteditable)$/.test(attr.name) ||
            /^data-?([a-zA-Z0-9\-_]+)/.test(attr.nodeName)
          ) {
            //user attribute
            let objectKey = attr.name;
            arrAttr.push({
              name: objectKey,
              value: doubleQuoteToSingle(attr.nodeValue),
            });
          }
        }
      }

      if (originalNode.classList && currentNode.props) {
        let className = [];
        let classList = originalNode.classList;
        let length = classList.length;
        for (let i = 0; i < length; i++) {
          let css = classList.item(i);
          //ignore page designer core CSS.
          if (!/\s*?(pg__[a-zA-Z\-_]*)/gm.test(css)) {
            className.push(css);
          }
        }
        if (className.length > 0) {
          currentNode.props.push({
            name: "className",
            value: className.join(" "),
          });
        }
      }

      //validate root.key not defined.
      if (!currentNode.key) {
        currentNode.key = uuid();
        $(originalNode).attr("data-pg-key", currentNode.key);
      }
      // genrate id when not have id
      if (currentNode.nodeType.id === 1) {
        if (currentNode && currentNode.props) {
          if (
            currentNode._tagName === "ons-radio" ||
            currentNode._tagName === "ons-checkbox"
          ) {
            let haveNoId =
              currentNode.props.findIndex(
                (p) => p.name === Attributes.INPUT_ID
              ) === -1;
            if (haveNoId) {
              let id = generateDOMID(originalNode, counterMapping);
              currentNode.props.push({
                name: Attributes.INPUT_ID,
                value: id,
              });
            }
          } else {
            let haveNoId =
              currentNode.props.findIndex((p) => p.name === Attributes.ID) ===
              -1;
            if (haveNoId) {
              let id = generateDOMID(originalNode, counterMapping);
              currentNode.props.push({
                name: Attributes.ID,
                value: id,
              });
            }
          }
        } else {
          let id = generateDOMID(originalNode, counterMapping);
          currentNode.props = [
            {
              name: Attributes.ID,
              value: id,
            },
          ];
        }
      }
      return tree;
    };

    /**
     * Build child element of parent.
     *
     * @param {HTMLElement|HTMLElement[]} domChildNode
     * @param {Tree} rootTree
     */
    const mapChildNode = (domChildNode, rootTree) => {
      var childNodes = domChildNode;
      if (childNodes) {
        let length = childNodes.length;
        // if we managed to find the parentNode for this rowToConvert, add it as a child
        if (rootTree) {
          //assign parent node = parentNodeTree arg
          for (let i = 0; i < length; i++) {
            //ignore statement
            let nextChild = childNodes[i];
            let hasIgnored = ignore ? ignoreNode(nextChild) : false;
            if (hasIgnored) {
              continue;
            }
            //calculate tree treeLevel
            let treeLevel = (rootTree.root.treeLevel || 0) + 1;
            //do add child in this parentNode.
            let children = buildDOMTree(nextChild, rootTree.root, treeLevel, i);
            mapChildNode(nextChild.childNodes, children);
            rootTree.root.addChild(children.root);
          }
        }
      }
    };

    // create root (body|rootNode)
    let tree = buildDOMTree(node, { key: null });
    // map childNode to root
    mapChildNode(node.childNodes, tree);
    return tree;
  }
}

window.T = Tree;
